<template>
  <div>
    <b-modal cancel-variant="outline-secondary" :cancel-title="$t('button.close')" centered no-close-on-backdrop
      hide-footer size="lg" v-model="isShow">
      <validation-observer ref="updateForm" #default="{ invalid }">
        <n-form-confirmation ref="confirmForm" key="updateForm" :form="$refs.updateForm" ok-variant="success"
          :disabled="invalid" @submit="save">
          <n-input :key="`input-${key}`" :fields="formInput" v-model="data">
            <template #dealerName="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider #default="{ errors }" :vid="item.field.key" :name="item.field.label"
                  :rules="item.field.rules">
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <b-input-group>
                    <b-form-input v-model="data[item.field.key]" :ref="item.field.key"
                      :placeholder="$t(item.field.label)" :name="item.field.key" :disabled="item.field.disabled"
                      :class="errors.length > 0 ? 'is-invalid' : null" autocomplete="off" />
                    <template #append>
                      <b-button variant="primary" v-b-tooltip.hover :title="$t('button.assignDealer')"
                        @click="assignDealer">
                        <feather-icon icon="UserPlusIcon" />
                      </b-button>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading type="button" variant="outline-secondary" :loading="loading" class="mr-1"
                @submit="hide">
                {{ $t("button.cancel") }}
              </n-button-loading>
              <n-button-loading type="button" variant="warning" :loading="loading" @submit="submit">
                {{ $t("button.reassignDealer") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-modal>

    <dealer-modal ref="dealerModal" @change="dealerChange" />
  </div>
</template>

<script>
  import {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
  } from "bootstrap-vue";
  import FormInput from "./formInput";
  import NInput from "@/components/NInput";
  import Repository from "@/repositories/RepositoryFactory";
  import NFormConfirmation from "@/components/NFormConfirmation";
  import NButtonLoading from "@/components/NButtonLoading";
  import NSingleSelect from "@/components/NSingleSelect";
  import DealerModal from "./dealer/DealerModal.vue";

  const BidRepository = Repository.get("bid");

  export default {
    components: {
      BModal,
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BInputGroupText,

      NFormConfirmation,
      NInput,
      NButtonLoading,
      NSingleSelect,
      DealerModal,
    },
    watch: {
      "data.type": function (value) {
        this.updateTitle(value);
        return;
      },
    },
    data() {
      return {
        key: 1,
        isShow: false,
        loading: false,
        data: {},
        options: [],
      };
    },
    methods: {
      assignDealer() {
        this.$refs.dealerModal.show(this.data);
      },
      dealerChange(item) {
        console.log({ item });

        this.data.dealerId = item.id;
        this.data.dealerName = item.name;
        this.data.finalPrice = item.amount;
      },
      show(item) {
        this.data = {
          id: item.id,
          dealerId: null,
          dealerName: null,
          finalPrice: null,
          remark: null,
          vehicleId: item.vehicleId,
        };
        this.options = [];
        this.key++;
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
      success() {
        this.$emit("success");
        this.hide();
      },
      submit() {
        this.$refs.updateForm.validate().then((success) => {
          if (success) {
            this.$refs.confirmForm.confirm();
          }
        });
      },
      save() {
        this.loading = true;
        BidRepository.reassign(this.data.id, {
          ...this.data,
        })
          .then((response) => {
            this.success();
          })
          .catch((error) => { })
          .then(() => {
            this.loading = false;
          });
      },
    },
    setup() {
      const formInput = [...FormInput];

      return {
        formInput,
      };
    },
  };
</script>